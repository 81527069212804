import React from "react";
import { createBrowserRouter } from "react-router-dom";

import { routes } from "./routes";

import { UserNotAllowed } from "../../features/403";
import { PageNotFound } from "../../features/404";
import { Faq } from "../../features/FAQ";
import { Activate } from "../Activate";
import { DashboardWithConfig } from "../Dashboard";
import { Filters } from "../Filters";
import { ForgotPassword } from "../ForgotPassword";
import { FrontendRedirectBasedOnEnv } from "../FrontendRedirectBasedOnEnv";
import History from "../History";
import { InviteContainer } from "../Invite";
import { List } from "../List";
import { ListVehicle } from "../ListVehicle";
import { Login } from "../Login";
import { Offer } from "../Offer";
import { Privacy } from "../Privacy";
import { MyProfile } from "../Profile";
import { Register } from "../Registration";
import { WithRegistrationWizard } from "../RegistrationWizard";
import { TermsAndConditions } from "../TermsAndConditions";
import { UnsubscribePage } from "../UnsubscribePage";
import { Vehicle } from "../VehicleState";
import { WebAppContainerWithConfig } from "../WebAppContainer";
import { PrivateRoute } from "./PrivateRoute";

const browserRouterRoutes = [
  {
    path: "/",
    element: <FrontendRedirectBasedOnEnv />,
  },
  {
    element: <WebAppContainerWithConfig />,
    children: [
      {
        path: routes.REGISTRATION,
        element: <Register />,
      },
      {
        path: routes.ACTIVATE,
        element: <Activate />,
      },
      {
        path: routes.INVITE,
        element: <InviteContainer />,
      },
      {
        path: routes.FORGOT_PASSWORD,
        element: <ForgotPassword />,
      },
      {
        path: routes.TERMS_AND_CONDITIONS,
        element: <TermsAndConditions />,
      },
      {
        path: routes.PRIVACY,
        element: <Privacy />,
      },
      {
        path: routes.LOGIN,
        element: <Login />,
      },
      {
        // TODO: check if the route is still required as it's not accessed from the app
        path: routes.VEHICLE,
        element: <Vehicle />,
      },
      {
        path: routes.FAQ,
        element: <Faq />,
      },
      {
        element: <PrivateRoute />,
        children: [
          {
            path: routes.UNSUBSCRIBE,
            element: <UnsubscribePage />,
          },
          {
            path: routes.REGISTRATION_WIZARD,
            element: <WithRegistrationWizard />,
          },
          {
            path: routes.USER_NOT_ALLOWED,
            element: <UserNotAllowed />,
          },
          {
            path: routes.LIST,
            element: <List />,
          },
          {
            path: routes.OFFER,
            element: <Offer />,
          },
          {
            path: routes.LIST_VEHICLE,
            element: <ListVehicle />,
          },
          {
            path: routes.STATE_LIST_VEHICLE,
            element: <ListVehicle />,
          },
          {
            path: routes.HISTORY,
            element: <History />,
          },
          {
            path: routes.FILTERS,
            element: <Filters />,
          },
          {
            path: routes.PROFILE,
            element: <MyProfile />,
          },
          {
            path: routes.DASHBOARD,
            element: <DashboardWithConfig />,
          },
        ],
      },
      {
        path: "*",
        element: <PageNotFound />,
      },
    ],
  },
];

export const browserRouter = createBrowserRouter(browserRouterRoutes);
