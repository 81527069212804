import React from "react";
import { useTranslation } from "react-i18next";

import clsx from "clsx";

import { updateName } from "../functions/filter";
import { Companies } from "./Companies";
import { Makes } from "./Makes";
import { Vehicle } from "./Vehicle";

import { Tab } from "@headlessui/react";

export const FilterForm = (props) => {
  const { filter, allCompanies, allMakes, onChange } = props;
  const { t } = useTranslation();

  if (!filter) return null;

  const tabLabels = [t("Dealers"), t("Makes"), t("Vehicle")];

  return (
    <div className="p-2">
      <div className="mb-2 font-semibold text-primary">{t("Filter name")}</div>
      <input
        type="text"
        placeholder={t("name")}
        className="form-control w-full rounded bg-white text-black lg:w-1/2"
        value={filter.name}
        onChange={(e) => onChange(updateName(filter, e.target.value))}
      />

      <div className="pt-5">
        <Tab.Group>
          <Tab.List className="flex">
            {tabLabels.map((label) => (
              <Tab
                key={label}
                className={({ selected }) =>
                  clsx(
                    "w-full py-1",
                    selected
                      ? "border-l border-r border-t bg-white"
                      : "border-b"
                  )
                }
              >
                {label}
              </Tab>
            ))}
          </Tab.List>
          <Tab.Panels>
            <Tab.Panel>
              <p className="pt-5">
                <span className="font-weight-bold">! {t("Please note")}:</span>
              </p>
              <p className="pt-2">
                {t(
                  "The dealers will receive an automatic e-mail in which we inform them of your registration. The dealers must activate your company within their network. If this does not happen after a while, please contact the dealer in person."
                )}
              </p>
              <Companies
                filter={filter}
                companies={allCompanies}
                onChange={onChange}
              />
            </Tab.Panel>
            <Tab.Panel>
              <Makes filter={filter} makes={allMakes} onChange={onChange} />
            </Tab.Panel>
            <Tab.Panel>
              <Vehicle filter={filter} onChange={onChange} />
            </Tab.Panel>
          </Tab.Panels>
        </Tab.Group>
      </div>
    </div>
  );
};
