import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { InfoContainer, Template } from "../components";
import { withConfig } from "../config/index";
import { RegistrationContainer } from "../features/registration-form/index";
import { routes } from "./navigation/routes";

const Register = (props) => {
  const { registration_steps } = props.config;
  const { t } = useTranslation();

  const [registrationComplete, setRegistrationComplete] = useState(false);

  const renderRegistrationForm = () => {
    return (
      <Template>
        <div className="bg-white">
          <section className="mt-3 py-10">
            <div className="flex justify-center">
              <div className="m-0 w-full rounded bg-white p-6 sm:m-6 sm:border sm:border-slate-200 xl:w-1/2">
                <div className="mb-5 text-center">
                  <h2 className="mb-4 text-2xl text-primary">
                    {t("Register")}
                  </h2>
                  <p>
                    <span>{t("Register your company")}</span>
                    <br />
                    <span>{t("and get access to thousands of vehicles.")}</span>
                  </p>
                </div>

                <RegistrationContainer
                  onRegistrationCompleted={() => setRegistrationComplete(true)}
                />

                <footer className="text-center">
                  <p className="mb-0 text-[13px] text-[#999]">
                    {t("Do you already have an account?")}{" "}
                    <Link
                      className="cursor-pointer font-semibold text-primary"
                      to={routes.LOGIN}
                    >
                      {t("Sign in")}
                    </Link>
                  </p>
                </footer>
              </div>
            </div>
          </section>
        </div>
      </Template>
    );
  };

  const renderRegistrationComplete = () => {
    const text =
      registration_steps.includes(6) && registration_steps.includes(7)
        ? t(
            "Then you can provide your personal and company data and set your preferences regarding dealers."
          )
        : t("Then you can provide your personal and company data.");

    const steps = [
      { step: 1, label: t("E-mail confirmation") },
      {
        step: 2,
        label: t("Personal info"),
      },
      {
        step: 3,
        label: t("Company info"),
      },
      {
        step: 4,
        label: t("Legal documents"),
      },
      {
        step: 5,
        label: t("Preferences"),
      },
      {
        step: 6,
        label: t("Filters"),
      },
      {
        step: 7,
        label: t("Requests"),
      },
    ];

    return (
      <Template>
        <div className="mt-3">
          <ul className="u-info mb-3 hidden justify-between bg-white p-2 text-center md:flex">
            {steps.map((stepItem, index) => {
              const activeStep = 1;
              if (registration_steps.includes(stepItem.step)) {
                return (
                  <li key={index} className="z-10 w-[100px]">
                    <div
                      className={`mx-auto flex size-6 items-center justify-center rounded-full ${
                        stepItem.step === activeStep
                          ? "bg-primary text-white"
                          : "border border-gray-300 bg-white text-gray-300"
                      }`}
                    >
                      <i className="fa fa-check text-[9px]"></i>
                    </div>
                    <h3 className="mb-3 text-lg font-semibold text-primary">
                      {stepItem.label}
                    </h3>
                  </li>
                );
              }
            })}
          </ul>
          <div className="flex flex-col justify-between bg-white p-5 md:flex-row">
            <div className="hidden w-1/2 md:block">
              <h5 className="text-xl text-primary">
                {t("E-mail confirmation")}
              </h5>
              <p className="pt-5">
                {t(
                  "Once you confirm your e-mail address, you can complete the registration."
                )}
              </p>
              <p>{text}</p>
            </div>

            <div className="w-full md:w-1/2">
              <InfoContainer
                icon="fa fa-envelope-open"
                text={
                  <h5 className="mb-2 font-bold text-highlightColor">
                    {t("You will receive a confirmation e-mail shortly.")}
                  </h5>
                }
                smallText={
                  <span className="text-light-gr font-size-12">
                    {t(
                      "Confirm your registration by clicking on the link in the mail."
                    )}
                    <br />
                    {t("Didn’t receive an e-mail? Check your spam inbox.")}
                  </span>
                }
                container={true}
              />
            </div>
          </div>
        </div>
      </Template>
    );
  };

  if (registrationComplete) {
    return renderRegistrationComplete();
  }

  return renderRegistrationForm();
};

const ConnectedComponent = withConfig(Register);

export { ConnectedComponent as Register };
