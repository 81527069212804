import { API_ENDPOINTS } from "../../../api/endpoints";
import { onFetchTokenRejected } from "./fetchToken";

export const fetchTokenByCodeService = async ({ code }, thunkAPI) => {
  const headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
    // "Accept-Language": currentState.localization.locale,
  };

  const options = {
    method: "POST",
    headers: headers,
    body: JSON.stringify({
      code: code,
    }),
  };

  try {
    const response = await fetch(API_ENDPOINTS.API_TOKEN_BY_CODE, options);

    if (response.ok) {
      return await response.json();
    }

    // TODO: revise the reject response in the backend
    // prepare responseValues
    const rejectedResponseValues = {
      data: await response.json(),
      statusCode: response.status,
    };
    return thunkAPI.rejectWithValue(
      onFetchTokenRejected(rejectedResponseValues)
    );
  } catch (err) {
    throw err;
  }
};
