import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { allowOnlyNumbers, updateAge, updateKm } from "../functions/vehicle";

export const Vehicle = (props) => {
  const { t } = useTranslation();
  const [kmError, setKmError] = useState("");
  const [ageError, setAgeError] = useState("");

  const { filter, onChange } = props;

  const handleUpdateAge = (_from, _to) => {
    const from = Number(_from);
    const to = Number(_to);

    if (to >= from) {
      onChange(updateAge(filter, from, to));
      setAgeError("");
    } else {
      setAgeError(t("Max age must be greater then min age"));
    }
  };

  const handleUpdateKm = (_from, _to) => {
    const from = Number(_from);
    const to = Number(_to);

    if (to >= from) {
      onChange(updateKm(filter, from, to));
      setKmError("");
    } else {
      setKmError(t("Max km must be greater then min km"));
    }
  };

  return (
    <div className="pt-5">
      <h6 className="mb-1">{t("Age (months)")}</h6>
      <div className="flex space-x-2 py-1">
        <div>
          <input
            type="text"
            placeholder={t("from")}
            value={filter.age_min === 0 ? "" : filter.age_min}
            onChange={(e) => {
              handleUpdateAge(allowOnlyNumbers(e.target.value), filter.age_max);
            }}
            onFocus={() => setAgeError("")}
            disabled={filter.age_max === 0}
            className="form-control w-[150px] rounded px-2 py-2"
          />
        </div>

        <i className="fa fa-arrow-right my-auto text-[20px]"></i>

        <div className="w-[150px]">
          <input
            type="text"
            placeholder={t("to")}
            value={filter.age_max === 0 ? "" : filter.age_max}
            onChange={(e) =>
              handleUpdateAge(filter.age_min, allowOnlyNumbers(e.target.value))
            }
            onFocus={() => setAgeError("")}
            className="form-control w-[150px] rounded px-2 py-2"
          />
        </div>
      </div>
      {ageError && (
        <div className="text-sm italic text-red-400">{ageError}</div>
      )}

      <h6 className="mb-1 mt-3">{t("Km")}</h6>
      <div className="flex space-x-2 py-1">
        <div>
          <input
            type="text"
            placeholder={t("from")}
            value={filter.km_min === 0 ? "" : filter.km_min}
            onChange={(e) =>
              handleUpdateKm(allowOnlyNumbers(e.target.value), filter.km_max)
            }
            onFocus={() => setKmError("")}
            disabled={filter.km_max === 0}
            className="form-control w-[150px] rounded px-2 py-2"
          />
        </div>

        <i className="fa fa-arrow-right my-auto text-[20px]"></i>

        <div>
          <input
            type="text"
            placeholder={t("to")}
            value={filter.km_max === 0 ? "" : filter.km_max}
            onChange={(e) =>
              handleUpdateKm(filter.km_min, allowOnlyNumbers(e.target.value))
            }
            onFocus={() => setKmError("")}
            className="form-control w-[150px] rounded px-2 py-2"
          />
        </div>
      </div>
      {kmError && <div className="text-sm italic text-red-400">{kmError}</div>}
    </div>
  );
};
