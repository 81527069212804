import React from "react";

import { WizardHeader } from "./WizardHeader";

import { filter } from "lodash";

export const Wizard = (props) => {
  const { children: pages, step, error } = props; //all wizard pages

  let selected;

  React.Children.forEach(pages, (page, index) => {
    if (index + 1 === step) {
      selected = page;
    }
  });

  return (
    <>
      <div className="hidden bg-white md:flex">
        <div className="mt-3 w-full">
          <ul className="u-info mb-3 flex justify-between bg-white p-2 text-center">
            {React.Children.map(
              filter(pages, (p) => p !== false),
              (page, index) => (
                <WizardHeader
                  key={index + 1}
                  number={index + 1}
                  title={page.props.title}
                  currentPage={step}
                />
              )
            )}
          </ul>
        </div>
      </div>

      {error !== "" && <div> {error} </div>}

      {React.cloneElement(selected, {
        step: step,
        totalSteps: React.Children.count(props.children),
        required: true,
      })}
    </>
  );
};
