import { hasToken } from "../session/selectors";
import { fetchProfileService } from "./services/fetchProfile";
import { fetchRegistrationStepService } from "./services/fetchRegistrationStep";
import { fetchTokenService } from "./services/fetchToken";
import { fetchTokenByCodeService } from "./services/fetchTokenByCode";
import { requestActivationMailService } from "./services/requestActivationMail";
import { auth_types, authFailure, authRequest, authSuccess } from "./types";

import { createAsyncThunk } from "@reduxjs/toolkit";

export const requestActivationMail = createAsyncThunk(
  "requestActivationMail",
  requestActivationMailService
);

export const fetchToken = createAsyncThunk("fetchToken", fetchTokenService);

export const fetchTokenByCode = createAsyncThunk(
  "fetchTokenByCode",
  fetchTokenByCodeService
);

export const fetchProfile = createAsyncThunk(
  "fetchProfile",
  fetchProfileService
);

export const fetchRegistrationStep = createAsyncThunk(
  "fetchRegistrationStep",
  fetchRegistrationStepService
);

export const login = ({ username, password }) => {
  return (dispatch, getState) => {
    dispatch(authRequest());

    // The return value of dispatch is used to determine what happens on auth failure
    return dispatch(fetchToken({ username, password }))
      .then((result) => {
        const token = hasToken(getState());

        if (token) {
          return Promise.all([
            dispatch(fetchProfile()),
            dispatch(fetchRegistrationStep()),
            //dispatch(fetchUserInfo()),
            //dispatch(fetchUserPreferences()),
          ]).then(() => {
            dispatch(authSuccess());
          });
        }
        // failed ...
        return dispatch(authFailure(result.payload));
      })
      .catch((error) => {
        dispatch(authFailure(error));
        return error;
      });
  };
};

export const loginByCode = ({ code }) => {
  return (dispatch, getState) => {
    dispatch(authRequest());

    dispatch(fetchTokenByCode({ code }))
      .then((result) => {
        const token = hasToken(getState());

        if (token) {
          return Promise.all([
            dispatch(fetchProfile()),
            dispatch(fetchRegistrationStep()),
          ]).then(() => dispatch(authSuccess()));
        }
        // Handles rejected response
        const failedResult = result.payload || result.error;
        dispatch(authFailure(failedResult));
      })
      .catch((error) => {
        dispatch(authFailure(error));
        return error;
      });
  };
};

export const loginByToken = (token) => {
  return (dispatch) => {
    dispatch({ type: auth_types.AUTH_BY_TOKEN, payload: { token: token } });

    return dispatch(fetchRegistrationStep()).then(() => {
      return dispatch({ type: auth_types.AUTH_SUCCESS });
    });
  };
};

export const logout = () => {
  return (dispatch) => {
    dispatch({ type: "CLEAR_STATE" });
  };
};
