import { API_ENDPOINTS } from "../../../api/endpoints";
import { mapFilterForRequest } from "../../listvehicles-filter/mappers";
import { getCriteria } from "../../listvehicles-filter/selectors";
import { mapSortForRequest } from "../../listvehicles-sort/mappers";
import { getActiveSorter } from "../../listvehicles-sort/selectors";
import { getOffset } from "../selectors";

const LIMIT = 10;

export const basketFetchService = async (
  { key, nextBatch = false },
  thunkAPI
) => {
  const currentState = thunkAPI.getState();
  // Get token from redux state
  const token = currentState.session.token;
  const limit = LIMIT;
  const offset = nextBatch ? getOffset(currentState, key) + limit : 0;
  const filter = getCriteria(currentState, -1);
  const sorter = getActiveSorter(currentState, -1);
  const states = "";
  const searchTerm = "";

  const url = `${
    API_ENDPOINTS.BASKET
  }?limit=${limit}&offset=${offset}&filter=${mapFilterForRequest(
    filter
  )}&sort=${mapSortForRequest(sorter)}`;

  const headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
    Authorization: "Token " + token,
    // "Accept-Language": currentState.localization.locale,
  };

  const options = {
    method: "GET",
    headers: headers,
  };

  try {
    const response = await fetch(url, options);

    if (response.ok) {
      return await response.json();
    }
    // TODO: revise the reject response in the backend
    // prepare responseValues
    const rejectedResponseValues = {
      data: await response.json(),
      statusCode: response.status,
    };
    return thunkAPI.rejectWithValue(rejectedResponseValues);
  } catch (err) {
    throw err;
  }
};
